'use client'

import { useEffect, useState } from 'react'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { Icon } from '@iconify/react'
import { useQueryClient } from '@tanstack/react-query'
import qs from 'qs'
import { useDebouncedCallback } from 'use-debounce'

import { useAdvertisersQuery } from '@/services/advertisers/query.service'
import { useCampaignsQuery } from '@/services/campaigns/query.service'
import { useCompanyOwnersQuery } from '@/services/hs_owners/query.service'
import { usePublisherBrandsQuery } from '@/services/publisher-brands/query.service'

import { statuses } from '@/constants/campaign.constant'
import { countries } from '@/constants/countries.constant'

import { cn } from '@/lib/utils'
import { CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import TabNav from '@/components/domain/common/tab-nav'
import DateSelection from '@/components/shared/filters/date-selection'
import SearchFilter from '@/components/shared/filters/search-selection'
import SingleSelection from '@/components/shared/filters/single-selection'

import DataTable from './_components/data-table'
import NumberSelection, { ValueRange } from './_components/number-selection'
import SettingsSelection from './_components/settings-selection'

const CampaignsView = () => {
  const router = useRouter()
  const path = usePathname()
  const searchParams = useSearchParams().toString()
  const params = qs.parse(searchParams, { allowEmptyArrays: false })
  const { data: campaigns, isFetching: isCampaignsFetching } =
    useCampaignsQuery(searchParams) as any
  const { data: owners, isLoading: isOwnersLoading } = useCompanyOwnersQuery()
  const { data: advertisers, isLoading: isAdvertisersLoading } =
    useAdvertisersQuery({ limit: 9999 })
  const { data: publisherBrands, isLoading: isPublisherBrandsLoading } =
    usePublisherBrandsQuery()

  const user: any = useQueryClient().getQueryData(['user'])

  const setFilter = (
    filter: string | string[],
    value: ValueRange | any,
    delimiter: string = '_',
  ) =>
    router.push(
      `${path}?${qs.stringify(
        {
          ...params,
          page: filter === 'page' ? params.page : undefined,
          ...(value && typeof value === 'object' && !Array.isArray(filter)
            ? Object.keys(value).reduce<
                Record<string, string | null | undefined>
              >(
                (filters, subFilter) => ({
                  ...filters,
                  [`${filter}${delimiter}${subFilter}`]: value[subFilter],
                }),
                {},
              )
            : Array.isArray(filter)
              ? filter.reduce<Record<string, string | null | undefined>>(
                  (filters, f, index) => ({
                    ...filters,
                    [f]: value[index] || null,
                  }),
                  {},
                )
              : { [filter as string]: value || null }),
        },
        {
          indices: false,
          skipNulls: true,
          strictNullHandling: true,
          filter: (prefix, value) => {
            if (
              (prefix === 'page' && value === '1') ||
              (prefix === 'limit' && value === '20')
            ) {
              return undefined
            }
            return value
          },
        },
      )}`,
    )

  useEffect(() => {
    params.page = params.page || undefined
  }, [params])

  const tabItems = [
    {
      label: 'All Campaigns',
      value: 'all',
      action: () => setFilter('ownerId', ''),
    },
    {
      label: 'My Campaigns',
      value: 'mycampaigns',
      action: () => setFilter('ownerId', user.ownerId),
    },
    {
      label: 'No Owners',
      value: 'noowners',
      action: () => setFilter(['ownerId', 'status'], ['false', '5']),
    },
  ]

  const onTabChange = (tab: string) => {
    tabItems.find((t) => t.value === tab)?.action?.()
  }

  const tabValue = !params.ownerId
    ? 'all'
    : params.ownerId === 'false' && params.status === '5'
      ? 'noowners'
      : params.ownerId === user.ownerId
        ? 'mycampaigns'
        : 'all'

  const [searchValue, setSearchValue] = useState(
    (params.search || '') as string,
  )
  const handleSearchFilter = useDebouncedCallback((searchValue) => {
    setFilter('search', searchValue || '')
  }, 500)
  const handleSearch = (e: any) => {
    setSearchValue(e.target.value)
    handleSearchFilter(e.target.value)
  }

  const [pageValue, setPageValue] = useState((params.page as string) || 1)
  const handlePageNav = (direction: 'next' | 'prev') => {
    const value =
      direction === 'next'
        ? Math.min(
            Number(params.page || 1) + 1,
            campaigns.totalPages || 1,
          ).toString()
        : Math.max(Number(params.page || 1) - 1, 1).toString()
    setFilter('page', value)
    setPageValue(value)
  }
  const handlePageFilter = useDebouncedCallback((pageValue) => {
    setFilter('page', pageValue || '')
  }, 500)
  const handlePage = (e: any) => {
    setPageValue(e.target.value)
    handlePageFilter(e.target.value)
  }

  const clearFilters = () => {
    setSearchValue('')
    router.push(`${path}`)
  }

  const clearOtherFilters = () => {
    setFilter(
      ['deeplink', 'outstandingBudget', 'storyLink', 'affiliate'],
      ['', '', '', ''],
    )
  }

  return (
    <>
      <div className="page-min-height">
        <CardTitle className="pb-4 pt-6 text-[28px] font-bold text-primary">
          Campaigns
        </CardTitle>

        <div className="top-[60px] z-10 flex flex-col border-b border-gray-300 pt-3 read-only:bg-background md:sticky">
          <TabNav
            value={tabValue}
            defaultValue="all"
            tabs={tabItems}
            onTabChange={onTabChange}
          />
          <div
            className={cn(
              'flex flex-col md:flex-row p-2 md:py-2 md:px-0 mb-2',
              !(searchParams && params.page !== '1' && params.limit !== '20') &&
                'items-center',
            )}>
            <div className="flex w-full flex-wrap gap-2">
              <SingleSelection
                label="Country"
                filter={'country'}
                value={params.country as string}
                options={countries.map((c) => ({ label: c, value: c }))}
                onSelected={setFilter}
              />
              <SingleSelection
                label="Status"
                filter={'status'}
                value={params.status as any}
                options={Object.keys(statuses).map((s) => ({
                  label: statuses[s],
                  value: s,
                }))}
                onSelected={setFilter}
              />
              <SearchFilter
                isLoading={isOwnersLoading}
                key={params.ownerId as any}
                label="Owner"
                filter="ownerId"
                value={
                  params.ownerId !== 'false' ? (params.ownerId as any) : ''
                }
                options={(owners?.data || []).map((o: any) => ({
                  label: o.firstName + ' ' + o.lastName,
                  value: o.ownerId,
                }))}
                onSelected={setFilter}
              />
              <SearchFilter
                isLoading={isAdvertisersLoading}
                key={params.advertiserId as string}
                label="Advertiser"
                filter="advertiserId"
                value={params.advertiserId as string}
                options={(advertisers?.data || []).map((a: any) => ({
                  label: a.name,
                  value: a.id,
                }))}
                onSelected={setFilter}
              />
              <SearchFilter
                isLoading={isPublisherBrandsLoading}
                key={params.brandId as string}
                label="Publisher Brand"
                filter="brandId"
                value={params.brandId as string}
                options={(publisherBrands || []).map((a) => ({
                  label: a.name,
                  value: a.id,
                }))}
                onSelected={setFilter}
              />
              <DateSelection
                label="Create Date"
                value={{
                  from: (params.createdAt_from as string) || null,
                  to: (params.createdAt_to as string) || null,
                }}
                filter="createdAt"
                onSelected={setFilter}
              />
              <DateSelection
                label="Start Date"
                value={{
                  from: (params.startDate_from as string) || null,
                  to: (params.startDate_to as string) || null,
                }}
                filter="startDate"
                onSelected={setFilter}
              />
              <DateSelection
                label="End Date"
                value={{
                  from: (params.endDate_from as string) || null,
                  to: (params.endDate_to as string) || null,
                }}
                filter="endDate"
                onSelected={setFilter}
              />
              <SingleSelection
                label="Charge Type"
                filter={'chargeType'}
                value={params.chargeType as string}
                options={['CPC', 'CPM'].map((c) => ({ label: c, value: c }))}
                onSelected={setFilter}
              />
              <NumberSelection
                label="Budget"
                filter="budget"
                value={{
                  min: params?.budget_min?.toString(),
                  max: params?.budget_max?.toString(),
                }}
                onSelected={setFilter}
              />
              <SingleSelection
                label="Placement"
                filter={'placement'}
                value={params.placement as string}
                options={[
                  { label: 'Marketplace', value: '0' },
                  { label: 'Pubfeed', value: '1' },
                ]}
                onSelected={setFilter}
              />
              <SettingsSelection
                onCleared={clearOtherFilters}
                onSelected={setFilter}
                label="Other"
              />
              {searchParams && params.page !== '1' && params.limit !== '20' && (
                <span
                  onClick={clearFilters}
                  className="h-[20px] cursor-pointer self-center font-semibold text-linkby-purple">
                  Clear Filters
                </span>
              )}
            </div>
            <Input
              value={searchValue}
              size="md"
              placeholder="Search by ID, Name or Headline"
              className="ml-auto mr-0 text-[0.8rem] md:w-[300px]"
              removeWrapper
              onChange={handleSearch}
            />
          </div>
        </div>

        <DataTable
          data={campaigns}
          isLoading={isCampaignsFetching}
          onRowClick={(row: any) => router.push(`${path}/${row.id}`)}
        />
      </div>
      {!isCampaignsFetching && (
        <div className="sticky bottom-0 flex h-12 items-center border-t bg-white px-2 dark:bg-muted">
          {campaigns.totalItems} campaigns found
          <div className="ml-auto flex items-center">
            <Icon
              icon="heroicons:chevron-left"
              className="mr-2 size-4 cursor-pointer"
              onClick={() => handlePageNav('prev')}
            />
            Page
            <Input
              radius="sm"
              size="sm"
              value={pageValue}
              className="mx-2 w-12"
              onChange={handlePage}
            />
            of {campaigns.totalPages}
            <Icon
              icon="heroicons:chevron-right"
              className="ml-2 size-4 cursor-pointer"
              onClick={() => handlePageNav('next')}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default CampaignsView

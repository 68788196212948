import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { getAdvertisers } from '.'

export const useAdvertisersQuery = (filters: any) => {
  return useQuery({
    queryKey: ['advertisers', filters],
    queryFn: () => getAdvertisers(filters),
    placeholderData: keepPreviousData,
  })
}
